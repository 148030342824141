<template>
  <!-- 横屏 -->
  <div class="v-video"> 
    <div class="sound-box-top" :style="{bottom: height < 700 ? '10px' : null}">
      <img @click="backHandler" class="back" src="@/assets/back.png"/>
    </div>
    <div class="xgplayer-rotate-fullscreen" style="width:100%;height:100%" id="mse"></div>
  </div>
</template>
<script>
import Player from 'xgplayer';
import 'xgplayer/dist/index'
export default {
  name: 'Video',
  mounted(){
    this.player = new Player({
      id:'mse',
      url: decodeURIComponent(this.$route.query.url),
      poster: decodeURIComponent(this.$route.query.poster),
      playsinline: true,
      width: '100%',
      height: '100%',
      rotateFullscreen: true,
      ignores: ['fullscreen', 'volume'],
      enableVideoDbltouch: true
    })
  },
  data() {
    return {
      height: document.documentElement.clientHeight,
      player: null
    }
  },
  methods: {
    backHandler() {
      // this.$router.back()
      window.wx.miniProgram.navigateBack()
    }
  },
  destroyed() {
    this.player.destroy()
  }
}
</script>

<style lang="scss" scoped>
.v-video {
  touch-action: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .sound-box-top {
    width: 46px;
    right: 10PX;
    bottom: 20px;
    z-index: 999;
    transform: rotate(90deg);
    position: fixed;
    > img {
      display: block;
      width: 46px;
      & + img {
        margin-top: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.xgplayer-skin-default.xgplayer-pause .xgplayer-start {
    display: inline-block;
    padding: 20PX;
    transform: translateX(-20PX) translateY(-20PX);
}
.xgplayer-skin-default .xgplayer-start {
  background: #fff!important;
}
.xgplayer-skin-default .xgplayer-start div svg {
  fill: #000!important;
}
.xgplayer-skin-default.xgplayer.xgplayer-ended .xgplayer-replay {
    margin-left: -10px;
    padding-top: 10px;
    display: none;
}
</style>
